<template>
  <router-link
    :to="`/transaction/pay/${orderNumber}?uuid=${applicationUuid}&type=${actionType}`"
    v-show="isAllowed"
    class="btn btn-primary"
    >{{ buttonName }}
  </router-link>
</template>

<script>
import ButtonMixin from '../mixins/buttonMixin';
export default {
  props: {
    allowedActions: {
      required: true,
      validator: value => {
        if (value !== null) {
          return value.constructor.name == 'Array';
        } else {
          return true;
        }
      },
    },
    type: {
      validator: value => {
        return typeof value === 'string' || value === null;
      },
      required: true,
    },
    applicationUuid: {
      validator: value => {
        return typeof value === 'string' || value === null;
      },
      required: true,
    },
    orderNumber: {
      validator: value => {
        return typeof value === 'string' || value === null;
      },
      required: false,
    },
  },
  mixins: [ButtonMixin],
  data() {
    return {
      actionName: 'PAY',
      actionType: 'RENT_TRANSACTION',
    };
  },
  methods: {},
};
</script>
